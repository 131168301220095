*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

html {
    font-size: 15px;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: inherit;
}

:root {
    --black-bg: #232f3e;
    --black-text: #232f3e;
    --black: #000;
    --light-grey: #e6e6e6;
    --blue: #064877;
}

.bg__black {
    background: var(--black-bg);
}

.bg__blue {
    background: var(--blue) !important;
}

.bg__light {
    background: var(--light-grey);
}

.text__shadow {
    text-shadow: 2px 2px 1px rgb(0 0 0 / 30%);
}

.text__blue {
    color: var(--blue);
}

.text__black {
    color: var(--black-text) !important;
}

.fs_12 {
    font-size: 12px;
}

.fs_13 {
    font-size: 13px;
}

.fs_14 {
    font-size: 14px;
}

.fs_15 {
    font-size: 15px;
}

.fs_16 {
    font-size: 16px;
}

.fs_17 {
    font-size: 17px;
}

.fs_18 {
    font-size: 18px;
}

.fs_19 {
    font-size: 19px;
}

.fs_20 {
    font-size: 20px;
}

.fs_21 {
    font-size: 21px;
}

.fs_22 {
    font-size: 22px;
}

.fs_23 {
    font-size: 23px;
}

.fs_24 {
    font-size: 24px;
}

.fs_25 {
    font-size: 25px;
}

.fs_26 {
    font-size: 26px;
}

.fs_27 {
    font-size: 27px;
}

.fs_28 {
    font-size: 28px;
}

.fs_29 {
    font-size: 29px;
}

.fs_30 {
    font-size: 30px;
}

.fs_31 {
    font-size: 31px;
}

.fs_32 {
    font-size: 32px;
}

.fs_33 {
    font-size: 33px;
}

.fs_34 {
    font-size: 34px;
}

.fs_35 {
    font-size: 35px;
}

.fs_36 {
    font-size: 36px;
}

.fs_37 {
    font-size: 37px;
}

.fs_38 {
    font-size: 38px;
}

.fs_39 {
    font-size: 39px;
}

.fs_40 {
    font-size: 40px;
}

.fs_41 {
    font-size: 41px;
}

.fs_42 {
    font-size: 42px;
}

.fs_43 {
    font-size: 43px;
}

.fs_44 {
    font-size: 44px;
}

.fs_45 {
    font-size: 45px;
}

.fs_46 {
    font-size: 46px;
}

.fs_47 {
    font-size: 47px;
}

.fs_48 {
    font-size: 48px;
}

.fs_49 {
    font-size: 49px;
}

.fs_50 {
    font-size: 50px;
}

.fs_51 {
    font-size: 51px;
}

.fs_52 {
    font-size: 52px;
}

.fs_53 {
    font-size: 53px;
}

.fs_54 {
    font-size: 54px;
}

.fs_55 {
    font-size: 55px;
}

.fs_56 {
    font-size: 56px;
}

.fs_57 {
    font-size: 57px;
}

.fs_58 {
    font-size: 58px;
}

.fs_59 {
    font-size: 59px;
}

.fs_60 {
    font-size: 60px;
}

.fw_100 {
    font-weight: 100;
}

.fw_200 {
    font-weight: 200;
}

.fw_300 {
    font-weight: 300;
}

.fw_400 {
    font-weight: 400;
}

.fw_500 {
    font-weight: 500;
}

.fw_600 {
    font-weight: 600;
}

.fw_700 {
    font-weight: 700;
}

.fw_800 {
    font-weight: 800;
}

.fw_900 {
    font-weight: 900;
}

h1,
h2 {
    font-family: 'Bebas Neue', cursive;
    margin: 0;
}

a,
button,
h3,
h4,
h5,
h6,
li,
p,
span {
    font-family: Poppins, sans-serif;
}

/* test start  */
.serv-cta-tel {
    border: 1px solid #fff;
    color: #fff;
    padding: 10px 20px;
    font-size: 14px;
    background: 0 0;
    z-index: 1;
}

.industry-section .serv-cta-btn.inr-tab-btn .serv-cta-tel {
    color: #000;
    border-color: #025D7E;
    position: relative;
    z-index: 1;
}

.industry-section .serv-cta-btn a:hover {
    color: #fff !important;
}

.cta__2.bg-detail.spacing__x .serv-cta-btn a {
    color: #fff;
    border-color: #fff;
}

.cta__2.bg-detail.spacing__x .serv-cta-btn a.serv-cta-chat:after,
.cta__2.bg-detail.spacing__x .serv-cta-btn a.serv-cta-chat:before,
.cta__2.bg-detail.spacing__x .serv-cta-btn a:hover {
    background: #000 !important;
}

.serv-cta-tel2 {
    border: 1px solid #fff;
    background-color: #025D7E;
    padding: 10px 20px;
    font-size: 14px;
    transition: all 0.7s ease 0s;
    transition-duration: 0.7s;
}

.cta-sec .serv-cta-btn.cta-tab-btn a,
.cta-sec .serv-cta-btn.cta-tab-btn:hover a.serv-cta-chat,
.serv-cta-btn.inr-tab-btn .serv-cta-tel:hover {
    color: #fff;
}

.navbar-nav li a {
    color: #fff;
}

.serv-cta-chat,
.serv-cta-chat:after,
.serv-cta-chat:before,
.serv-cta-tel {
    transition-duration: .7s;
}

.serv-cta-btn.inr-tab-btn .serv-cta-tel:hover::after,
.serv-cta-btn.inr-tab-btn .serv-cta-tel:hover::before,
.serv-cta-btn.inr-tab-btn a.serv-cta-chat:after,
.serv-cta-btn.inr-tab-btn a.serv-cta-chat:before,
.why-box:hover {
    background: #025D7E;
}

.serv-cta-btn.inr-tab-btn a.serv-cta-chat:hover {
    border-color: #025D7E;
    color: #000;
}

.serv-cta-btn.inr-tab-btn a.serv-cta-chat:hover::after,
.serv-cta-btn.inr-tab-btn a.serv-cta-chat:hover::before {
    background: 0 0;
}

.serv-cta-chat {
    color: #fff;
    padding: 10px 15px;
    font-size: 14px;
    margin-left: 15px;
    border: 1px solid #000;
    z-index: 1;
}

.serv-cta-chat:hover {
    border-color: #fff;
    color: #fff;
    background: 0 0;
}

.serv-cta-chat:after,
.serv-cta-chat:before {
    content: '';
    background: #000;
    position: absolute;
    left: 0;
}

.serv-cta-chat:before {
    top: 0;
    bottom: 50%;
    right: 0;
    z-index: -1;
}

.serv-cta-chat:after {
    bottom: 0;
    top: 50%;
    z-index: -1;
    right: 0;
}

.serv-cta-chat:hover::after {
    top: 0 !important;
    background: 0 0;
}

.serv-cta-chat:hover::before {
    bottom: 0 !important;
    background: 0 0;
}

.serv-cta-tel:after,
.serv-cta-tel:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    z-index: -1;
    transition-duration: .7s;
    background: 0 0;
    right: 0;
}

.navbar-nav a {
    color: #fff !important;
}

.dropdown-menu .dropdown-item:hover {
    color: #16181b !important;
    text-decoration: none;
    background-color: #f8f9fa;
}

.dropdown-menu.show {
    background-color: #0c5b7e !important;
    border-radius: 6px;
}

.serv-cta-tel:hover {
    color: #fff;
    border-color: #000;
}

.serv-cta-tel:hover::before {
    background: #000;
    bottom: 50%;
    transition-duration: .7s;
}

.serv-cta-tel:hover::after {
    background: #000;
    top: 50%;
    transition-duration: .7s;
}

.serv-cta-chat2 {
    background-color: #025D7E;
    padding: 10px 20px;
    color: #fff !important;
}

header {
    position: absolute !important;
    background-color: transparent !important;
    z-index: 999;
    padding: 0;
    width: 100%;
    top: 0%;
}

header .navbar-nav .nav-link {
    margin: 5px;
}

header .navbar-nav a {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
}

.banner-img img {
    width: 65%;
    margin: 0 auto;
    display: table;
}

b.text_off {
    animation: blinker 1s linear 0s infinite normal none running;
    box-sizing: border-box;
    color: #ffe606;
    font-family: "Poppins";
    font-size: 22px;
    font-weight: 700;
    line-height: 15px;
    margin: 0px;
    padding: 0px;
    text-align: center;
    text-shadow: #ffffff45 0px -1px 1px;
    word-wrap: break-word;
}

@keyframes blinker {
    0% {
        color: #ffe606;
    }

    49% {
        color: #ffe606;
    }

    60% {
        color: transparent;
    }

    99% {
        color: transparent;
    }

    100% {
        color: #ffe606;
    }
}

/* end  */

.get_quote {
    background: var(--blue) !important;
    padding: 10px 40px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}

.live_btn {
    background: #fff;
    padding: 10px 40px;
    font-size: 16px;
    font-weight: 600;
    color: var(--black-text);
    border: 2px solid var(--blue);
}

.live_btn:hover {
    background: none;
    border: 2px solid var(--orange);
    color: #fff;
}

.lh_1 {
    line-height: 1.1;
}

.banner__form.card {
    background: #fff;
    border-radius: 10px 0 0 10px;
}

.banner__form form input,
.banner__form form textarea,
.banner__form form select {
    border: 2px solid #0000005e;
    border-radius: 10px;
}

.banner__form form input:focus,
.banner__form form textarea:focus,
.banner__form form select:focus {
    border: 2px solid #000;
}

.banner__form input,
.banner__form textarea,
.banner__form select option {
    color: #000 !important;
}

/* form select option{
    color: #000;
} */

#sideContactBtns {
    /* position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    text-align: right;
    z-index: 99999;
    display: block; */
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    text-align: right;
    z-index: 99999;
    display: block;
}

#sideContactBtns button.rotate90 {
    /* transform: rotate(270deg) translate(32px, 40px);
    max-width: none;
    border-radius: 6px 6px 0 0;
    max-height: 60px;
    margin-top: auto;
    margin-bottom: 14px; */
    transform: rotate(270deg) translate(42px, 50px);
    max-width: none;
    border-radius: 6px 6px 0 0;
    max-height: 60px;
    margin-top: auto;
    margin-bottom: 14px;
}

#sideContactBtns a,
#sideContactBtns button {
    /* background-color: var(--blue);
    display: block;
    text-align: center;
    padding: 10px 16px;
    transition: all .3s ease;
    color: #fff;
    font-size: 20px;
    box-shadow: 0 0 10px rgb(0 0 0/.1);
    margin-top: 8px;
    border: 2px solid var(--orange);
    border-radius: 6px 0 0 6px;
    margin-right: -2px;
    margin-left: auto;
    max-width: 60px;
    height: 60px; */
    background-color: #0c5b7e;
    display: block;
    text-align: center;
    padding: 10px 16px;
    transition: all .3s ease;
    color: #fff;
    font-size: 20px;
    box-shadow: 0 0 10px rgb(0 0 0/.1);
    margin-top: 8px;
    border: 2px solid #fff;
    border-radius: 6px 0 0 6px;
    margin-right: -2px;
    margin-left: auto;
    max-width: 60px;
    height: 60px;
}

#sideContactBtns a:hover,
#sideContactBtns button:hover {
    background-color: #103440;
    color: #fff;
}


/* home banner start  */
.home-banner {
    background: url(../images/banner.webp) no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    padding-top: 150px;
    width: 100%;
}

.home-banner .card {
    background: #00000078;
    border-radius: 6px;
    padding: 25px 30px;
    width: auto;
    margin: 5px 0 0;
    box-shadow: 5px 6px 30px #00000012;
}

.home-banner .card input {
    border: 0;
    padding: 15px 20px;
    border-radius: 6px;
    color: #fff;
    font-size: 16px;
    background: #00000029;
    border: 0.5px solid #ffffffa1;
}

.home-banner .card input::placeholder {
    color: #fff;
}

/* home banner end  */

.checklist li svg {
    color: var(--blue);
}

.packages-section {
    background: url(../images/packages.webp) no-repeat;
    background-size: cover;
    background-position: center;
}

.modal input,
.modal textarea {
    border-radius: 0;
    border: 2px solid #fff;
    background: transparent;
}

.modal form input,
.modal form textarea,
.modal form select option {
    color: #000 !important;
}

.modal input:focus,
.modal textarea:focus {
    color: var(--black);
    border: 2px solid var(--black-text);
    background: var(--light-grey);
}

@media (min-width: 768px) {
    .col-md-2half {
        flex: 0 0 auto;
        width: 20%;
    }
}

.bg__linear {
    background: linear-gradient(145deg, rgba(255, 153, 0, 1), rgba(35, 47, 62, 1)) !important;
}

.cta-section {
    background: url(../images/why-choose.webp) no-repeat;
    background-position: center;
    background-size: cover;
}

.why-choose {
    background: url(../images/why-choose.webp) no-repeat;
    background-position: center;
    background-size: cover;
}

.why-choose .col-md-6.mb-3 {
    padding: 0;
}

.why-choose-box {
    background: var(--light-grey);
    margin: 0 20px;
}

.why-choose-box:hover {
    background: var(--blue) !important;
}

.why-choose-box:hover h2,
.why-choose-box:hover p {
    color: #fff;
}

.why-choose-box .card-body {
    padding: 25px 20px;
}

.why-choose-box:hover {
    background: var(--orange);
}

@media(max-width:768px) {
    .home-banner {
        height: 100%;
        padding: 50px 0px;
    }
}

.industry-section .nav-item .nav-link.active button {
    background: var(--blue);
    color: #fff;
}

.industry-section .nav-item .nav-link button {
    border-radius: 4px;
    padding: 5px 10px;
    font-weight: 400;
    border: none;
    font-family: 'Bebas Neue', cursive;
    text-transform: uppercase;
    color: var(--black);
    background: transparent;
    font-size: 25px;
}

.port-box:hover:before {
    -webkit-transform: scale(400);
    -moz-transform: scale(400);
    -ms-transform: scale(400);
    -o-transform: scale(400);
    transform: scale(400);
}

.port-box:before {
    -o-transition: .5s linear .3s;
    -moz-transition: .5s linear .3s;
    -ms-transition: .5s linear .3s;
    -webkit-transition: .5s linear .3s;
    transition: .5s linear .3s;
}

.port-box:hover:after {
    -webkit-transform: scale(400);
    -moz-transform: scale(400);
    -ms-transform: scale(400);
    -o-transform: scale(400);
    transform: scale(400);
}

.port-box:after {
    -o-transition: .5s linear .3s;
    -moz-transition: .5s linear .3s;
    -ms-transition: .5s linear .3s;
    -webkit-transition: .5s linear .3s;
    transition: .5s linear .3s;
}

.port-box .box-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: 0 0;
    color: #fff;
    padding-top: 25px;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -ms-transition: .3s linear;
    -o-transition: .3s linear;
    -webkit-transition: .3s linear;
    -moz-transition: .3s linear;
    transition: .3s linear;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.port-box:hover .box-content {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -moz-transition-delay: 0.4s;
    -webkit-transition-delay: 0.4s;
    -o-transition-delay: 0.4s;
    -ms-transition-delay: 0.4s;
    transition-delay: 0.4s;
}

.port-box .box-content svg {
    font-size: 40px;
    font-weight: bold;
    color: #fff;
}

.port-box:after,
.port-box:before {
    content: "";
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background: rgba(0, 0, 0, .35);
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
}

.portfolio-section {
    background: url(../images/portfolio.webp) no-repeat;
    background-position: center;
    background-size: cover;
}

.services-section {
    background: url(../images/services.webp) no-repeat;
    background-position: center;
    background-size: cover;
}

.serv-bx {
    padding: 30px 20px;
    border-radius: 10px;
}

.contact-banner {
    background: url(../images/contact-banner.webp) no-repeat;
    background-size: cover;
    background-position: center;
    height: 400px;
    width: 100%;
}

.portfolio-banner {
    background: url(../images/portfolio-banner.webp) no-repeat;
    background-size: cover;
    background-position: center;
    height: 400px;
    width: 100%;
}

.testimonial-banner {
    background: url(../images/testimonial-banner.webp) no-repeat;
    background-size: cover;
    background-position: center;
    height: 400px;
    width: 100%;
}

.single-review-slider-nav .slick-slide img {
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #ccc;
}

.our-testimonial .single-review-box img {
    border-radius: 50%;
}

.our-testimonial {
    background: url(../images/footer-bg.webp) no-repeat;
    background-position: center;
    background-size: cover;
}

.best-heading-here {
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    background: #025D7E;
    display: initial;
    padding: 0 8px;
    border-radius: 5px;
}

.get-in-touch p a {
    font-size: 16px;
    color: #fff;
    font-weight: 300;
    margin: 0 10px;
}

.get-in-touch p a svg {
    color: #025D7E;
}

.bless_friday {

    width: 250px;

    height: 140px;

    background: url("../images/bless-friday-bg.webp");

    color: #fff;

    position: fixed;

    bottom: 30px;

    left: 20px;

    display: flex;

    justify-content: center;

    align-items: center;

    flex-direction: column;

    border: 2px solid #fff;

    border-radius: 15px;

    box-shadow: 0 0 1px 2px #58eaff, 0 0 1px 2px #58eaff inset;

    filter: drop-shadow(0px 0px 5px #58eaff);

    z-index: 9;

    cursor: pointer;

    animation: 1s linear infinite bf-animate
}


.countdown {
    display: block;
    width: 65%;
    text-align: center;
    color: #fff;
    font-size: 22px;
    margin-top: 6px;
    padding: 5px 0;
    border: 2px solid #fff;
    border-right: 0;
    border-left: 0;
    border-radius: 6px;
    animation: 1s linear infinite bf-animate-2;
}

@keyframes bf-animate {



    0%,

    100% {

        filter: drop-shadow(0px 0px 5px #58eaff);

        transform: translateY(-5px)
    }



    50% {

        filter: drop-shadow(0px 0px 5px #e50b48);

        box-shadow: 0 0 1px 2px #e50b48, 0 0 1px 2px #e50b48 inset;

        border-color: #000;

        transform: translateY(0)
    }



    80% {

        filter: drop-shadow(0px 0px 5px #e50b48);

        box-shadow: 0 0 1px 2px #e50b48, 0 0 1px 2px #e50b48 inset;

        border-color: #000
    }

}



.bless_friday h2 {

    font-size: 25px !important;

    -webkit-text-stroke: 1.5px #56575b;

    text-shadow: 1px 2px 15px #56575b;

    filter: drop-shadow(0px 0px 25px #56575b);

    font-weight: 500;

    color: #fff;

    font-family: Yantramanav, sans-serif
}



.bless_friday h2 span {

    padding-left: 5px;

    -webkit-text-stroke: 1px #e50b48;

    text-shadow: 1px 2px 15px #e50b48;

    filter: drop-shadow(0px 0px 25px #e50b48);

    color: #fff;

    font-family: Yantramanav, sans-serif
}



.bless_friday .bless_friday_top {

    width: 80px;

    height: 34px;

    background: 0 0;

    color: #fff;

    border: 2px solid #fcffeb;

    display: flex;

    justify-content: center;

    align-items: center;

    border-radius: 5px;

    box-shadow: 0 0 1px 2px #eabb17, 0 0 1px 2px #eabb17 inset;

    filter: drop-shadow(0px 0px 5px #e70046);

    margin-bottom: 5px;

    animation: 1s linear infinite bf-animate-2
}



@keyframes bf-animate-2 {



    0%,

    100% {

        filter: drop-shadow(0px 0px 5px #eabb17)
    }



    50% {

        filter: drop-shadow(0px 0px 5px #e50b48);

        box-shadow: 0 0 1px 2px #e50b48, 0 0 1px 2px #e50b48 inset;

        border-color: #000;

        transform: translateX(0)
    }



    80% {

        filter: drop-shadow(0px 0px 5px #e50b48);

        box-shadow: 0 0 1px 2px #e50b48, 0 0 1px 2px #e50b48 inset;

        border-color: #000
    }

}



.bless_friday .bless_friday_top p {

    font-size: 20px !important;

    margin-bottom: 0;

    color: #fff;

    font-family: Yantramanav, sans-serif;

    padding: 0
}



.bless_friday #veteran-countdown {

    display: flex;

    justify-content: space-evenly;

    align-items: center;

    width: 80%;

    margin-top: 6px;

    padding: 5px 0;

    border: 2px solid #fff;

    border-right: 0;

    border-left: 0;

    border-radius: 6px
}



.bless_friday #veteran-countdown .veteran-counter,

.cta-sec-2 .hd-txt,

.get-in-touch,

.industry-sec .hd-txt h2,

.modaL_order2 #countdown .labels {

    text-align: center
}



.bless_friday #veteran-countdown .veteran-counter span.ping {

    font-size: 18px !important;

    font-weight: 600;

    color: #fff;

    margin: 0;

    padding: 0;

    display: flex;

    border-radius: 4px;

    justify-content: center;

    align-items: center;

    font-family: Yantramanav, sans-serif
}



@keyframes ping {

    0% {

        filter: drop-shadow(1px 1px 10px #e50b48)
    }



    50% {

        filter: unset
    }



    100% {

        filter: drop-shadow(1px 1px 10px #fff)
    }

}



.bless_friday #veteran-countdown .veteran-counter span::before {

    content: attr(b);

    display: block;

    text-shadow: 0 0 12px #eeeeee80, 0 0 32px #eeeeee4d, 0 24px 48px #eeeeee1a, 0 -24px 48px #eeeeee1a
}



.bless_friday #veteran-countdown .veteran-counter b {

    font-size: 14px !important;

    font-weight: 800;

    letter-spacing: 1px;

    font-family: Montserrat, sans-serif;

    margin: 0;

    padding: 0
}



@media screen and (max-width:1024px) {

    .bless_friday {

        width: 200px;

        height: 129px
    }



    .bless_friday .bless_friday_top {

        width: 72px;

        height: 30px
    }



    .bless_friday .bless_friday_top p {

        font-size: 18px !important
    }



    .bless_friday h2 {

        font-size: 22px !important
    }

}

.popup__form {
    width: 970px;
    height: 570px;
    background: url(../images/background-popup.webp) 20% 50%/cover no-repeat #000;
    border-radius: 10px;
    padding: 3rem;
    position: relative;
    top: 50%;
    display: inline-block;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: 1.2s linear infinite filter-shadow;
    overflow: hidden;
}

.modal-content {
    height: 100vh;
    background-color: transparent;
}

.popup__form input,
.popup__form textarea {
    font-style: 16px;
    font-weight: 400;
    background: #fff;
    color: var(--black);
    padding: 0 21px;
    width: 100%;
    height: 52px;
    border-radius: 4px;
}

.popup__form input::placeholder,
.popup__form textarea::placeholder {
    color: var(--black);
}

.popup__form button {
    display: block;
    width: 100%;
}

.popup__form textarea {
    height: 100px !important;
    resize: none;
    padding: 20px !important;
}

.fancybox-close-small.close {
    padding: 0;
    background-color: #025D7E !important;
    border: none;
    opacity: 1;
    color: #fff;
    width: 31px;
    height: 31px;
    border-radius: 100%;
    z-index: 11;
    position: absolute;
    top: 5px;
    right: 5px;
    font-weight: bold;
}

.single-review-box p {
    height: 191px;
    overflow-y: auto;
}

.single-review-box p::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.single-review-box p::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.single-review-box p::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 30px;
}

/* Handle on hover */
.single-review-box p::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media only screen and (min-width: 300px) and (max-width: 519px) {
    .popup__form {
        width: 100% !important;
        top: 0 !important;
        left: 0 !important;
        transform: none !important;
    }

    .popup__form,
    .modal-content {
        height: auto !important;
    }

    .popup__form .card .card-body {
        transform: none;
        left: 0 !important;
        transform: translateX(0) !important;
        top: 0 !important;
        position: relative !important;
        z-index: 9 !important;
    }

    .popup__form .card .card-body {
        width: 100%;
        padding: 20px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .home-banner h1 {
        padding-top: 50px;
        font-size: 40px;
    }

    header .navbar {
        background: black;
    }
}

@media only screen and (min-width: 300px) and (max-width: 519px) {

    .align-items-center,
    .serv-cta-btn,
    .serv-cta-btn.inr-tab-btn.header-btn.cta-tab-btn {
        flex-wrap: wrap;
    }

    .serv-cta-btn a {
        text-align: center !important;
        width: 100%;
        margin: 0 0 15px;
        margin-left: 0 !important;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .serv-cta-btn {
        justify-content: center;
    }
}